import React, { useState, useEffect } from "react";
import { Table, Card, Space } from "lib";
import { Button, TablePaginationConfig, message, Form, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Layout } from "layout";
import {
  RatingScalesClient,
  RatingScaleBriefDto,
  RatingScalePointDto,
  UpdateRatingScalePointCommand,
} from "@api";
import { showError } from "@action";
import { FormattedMessage } from "react-intl";
import SurveyMenu from "@components/Admin/Survey/SurveyMenu";
import { ACTION, SECTION, SubpageAuth } from "@services/auth";
import GeneralFormModal from "@components/GeneralFormModal";
import RatingScalePointNestTable from "@components/Admin/Survey/RatingScalePointNestTable";
import { navigate } from "gatsby";
import ContentKeyValueTable from "@components/Admin/Language/ContentKeyValueTable";

const defaultOrderBy = "id";
const defaultSortOrder = "descend";
const defaultPagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
  position: ["topRight", "bottomRight"],
};

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const Page = () => {
  const auth = new SubpageAuth(SECTION.Admin, "Survey", "Survey");
  if (auth.cannot(ACTION.Access)) {
    navigate("/403/");
    return <></>;
  }

  const [modal, modalContextHolder] = Modal.useModal();
  const [messageApi, messageContextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [createModalVisible, setCreateModalVisible] = useState(false);

  const allLanguageFetch = {
    fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
      const _init = {
        ...init,
        headers: {
          ...init?.headers,
          "accept-language": "*",
        },
      };
      return fetch(url, _init);
    },
  };

  const ratingScalesClient = new RatingScalesClient(
    undefined,
    allLanguageFetch
  );

  const [state, setState] = useState({
    items: [] as RatingScaleBriefDto[],
    pagination: defaultPagination,
    loading: false,
  });

  const [expandedRowKeys, setExpandedRowKeys] = useState(
    undefined as React.Key[] | undefined
  );

  //   const [keywords, setKeywords] = useState(
  //     undefined as string | undefined | null
  //   );

  async function fetchItems(
    params: any = { pagination: state.pagination }
    // _keywords: string | undefined | null = undefined
  ) {
    if (auth.cannot(ACTION.List, "Survey")) return;

    setState({
      ...state,
      loading: true,
    });

    try {
      const res = await ratingScalesClient.getRatingScalesWithPagination(
        // _keywords ?? keywords,
        params.pagination.current,
        params.pagination.pageSize,
        capitalizeFirstLetter(params.sortField ?? defaultOrderBy),
        (params.sortOrder ?? defaultSortOrder) == "ascend"
      );
      setState({
        ...state,
        items:
          (res.items as RatingScaleBriefDto[]) || ([] as RatingScaleBriefDto[]),
        pagination: {
          ...params.pagination,
          total: res.totalCount,
        },
        loading: false,
      });
    } catch (err) {
      setState({
        ...state,
        loading: false,
      });
      showError(err);
    }
  }

  async function handleTableChange(
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) {
    await fetchItems({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  }

  async function handleAddContent(data: any) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticCreatingMessage" />
    );
    try {
      await ratingScalesClient.create(data);
      messageApi.success(<FormattedMessage id="StaticCreateSuccessMessage" />);
      setCreateModalVisible(false);
      await fetchItems();
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  function handleCancelAddContent() {
    setCreateModalVisible(false);
  }

  async function handleEditContent(parent: any, target: any, data: any) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticUpdatingMessage" />
    );

    try {
      if (!target || !parent) {
        throw "Error";
      }

      await ratingScalesClient.update(
        parent.id as number,
        new UpdateRatingScalePointCommand({
          id: parent.id,
          label: { [target.key]: data.value },
        })
      );
      messageApi.success(<FormattedMessage id="StaticUpdateSuccessMessage" />);

      setExpandedRowKeys([]); // collapse all expended
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleDelete(id: number) {
    modal.confirm({
      title: <FormattedMessage id="StaticConfirmDeleteMessage" />,
      okText: <FormattedMessage id="StaticYesLabel" />,
      cancelText: <FormattedMessage id="StaticNoLabel" />,
      async onOk() {
        const hide = messageApi.loading(
          <FormattedMessage id="StaticDeletingMessage" />
        );
        try {
          await ratingScalesClient.delete(id);
          messageApi.success(
            <FormattedMessage id="StaticDeleteSuccessMessage" />
          );
          fetchItems();
        } catch (err) {
          showError(err);
        } finally {
          hide();
        }
      },
    });
  }

  //   async function handleKeywordsSearch(values: any) {
  //     setKeywords(values.keywords);
  //     await fetchItems(undefined, values.keywords);
  //   }

  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    //   sorter: true,
    // },
    {
      // title: "Title",
      title: <FormattedMessage id="SurveyTableHeaderTitle" />,
      dataIndex: "title",
      key: "title",
      sorter: true,
    },
    {
      // title: "Point Of Scale",
      title: <FormattedMessage id="SurveyTableHeaderPointOfScale" />,
      dataIndex: "pointOfScale",
      key: "pointOfScale",
      sorter: true,
    },
    {
      // title: "Action",
      title: <FormattedMessage id="SurveyTableHeaderAction" />,
      key: "action",
      render: (text: any, record: RatingScaleBriefDto) => (
        <Space>
          {auth.can(ACTION.Delete, "Survey") ? (
            <Button
              type="primary"
              onClick={() => handleDelete(record.id as number)}
            >
              <FormattedMessage id="StaticDeleteButton" />
            </Button>
          ) : null}
        </Space>
      ),
    },
  ];

  const columnsForPoint = [
    {
      // title: "ID",
      title: <FormattedMessage id="SurveyTableHeaderId" />,
      dataIndex: "id",
      key: "id",
    },
    {
      // title: "Value",
      title: <FormattedMessage id="SurveyTableHeaderValue" />,
      dataIndex: "value",
      key: "value",
    },
    {
      // title: "Content",
      title: <FormattedMessage id="SurveyTableHeaderContent" />,
      dataIndex: "label",
      key: "label",
      render: (text: any, record: RatingScalePointDto) => (
        <ContentKeyValueTable
          content={record.label}
          parent={record}
          handleAction={handleEditContent}
          editable={auth.can(ACTION.Edit, "Survey")}
        />
      ),
      className: "content-table-cell",
    },
  ];

  const onTableRowExpand = (expanded: boolean, record: any) => {
    const keys = [];
    if (expanded) {
      keys.push(record.id);
    }

    setExpandedRowKeys(keys);
  };

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <Layout title="SurveySurveyNavigationTitle">
      <Card>
        <Space direction="vertical" size="middle">
          <SurveyMenu />
          <Space justify="space-between">
            <Space justify="flex-start">
              <Form
                form={form}
                name="horizontal_login"
                layout="inline"
                // onFinish={handleKeywordsSearch}
              >
                {/* <Form.Item name="keywords">
                  <Input placeholder={StaticKeywords}  />
                </Form.Item>
                <Form.Item shouldUpdate>
                  {() => (
                    <Button type="primary" htmlType="submit">
                      <FormattedMessage id="StaticSearchLabel" />
                    </Button>
                  )}
                </Form.Item> */}
              </Form>
            </Space>
            <Space wrap justify="flex-end">
              {auth.can(ACTION.Create, "Survey") ? (
                <Button
                  type="primary"
                  onClick={() => {
                    setCreateModalVisible(true);
                  }}
                >
                  <Space>
                    <PlusOutlined />
                    <FormattedMessage id="SurveySurveyContentAddButton" />
                  </Space>
                </Button>
              ) : null}
            </Space>
          </Space>
          <Table
            bordered
            dataSource={[...state.items]}
            columns={columns}
            rowKey={(r) => r.id}
            pagination={state.pagination}
            loading={state.loading}
            onChange={handleTableChange}
            expandable={{
              expandedRowKeys: expandedRowKeys,
              onExpand: onTableRowExpand,
              expandedRowRender: (record, index, indent, expanded) => (
                <RatingScalePointNestTable
                  ratingScaleId={record.id}
                  columns={columnsForPoint}
                  expanded={expanded}
                />
              ),
            }}
          />
        </Space>
      </Card>
      <GeneralFormModal
        visible={createModalVisible}
        handleAction={handleAddContent}
        handleCancelAction={handleCancelAddContent}
        titleId={"SurveySurveyModalAddTitle"}
        editableFields={[
          {
            name: "title",
            labelId: "SurveySurveyStaticTitleLabel",
            type: "string",
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage id="SurveySurveyStaticMissingTitleRule" />
                ),
              },
            ],
          },
          {
            name: "pointOfScale",
            labelId: "SurveySurveyStaticPointOfScaleLabel",
            type: "string",
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage id="SurveySurveyStaticMissingPointOfScaleRule" />
                ),
              },
            ],
          },
        ]}
      />
      {modalContextHolder}
      {messageContextHolder}
    </Layout>
  );
};

export default Page;
