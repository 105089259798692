import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "antd";
import { FormattedMessage } from "react-intl";
import GeneralFormModal from "@components/GeneralFormModal";

type field = {
  key: string;
  value: string;
};

type HeaderProps = {
  content: { [key: string]: string } | undefined;
  parent: any;
  handleAction: any;
  editable: boolean;
};

const ContentKeyValueTable = ({
  content,
  parent,
  handleAction,
  editable,
}: HeaderProps): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modal, modalContextHolder] = Modal.useModal();

  const [state, setState] = useState([] as field[]);

  const [visible, setVisible] = useState(false);
  const [editModalObject, setEditModalObject] = useState(
    undefined as field | undefined
  );

  function OpenModalEditContent(keyvalue: field) {
    setVisible(true);
    setEditModalObject(keyvalue);
  }

  async function handleEditContent(data: any) {
    setVisible(false);

    await handleAction(parent, editModalObject, data);
  }

  function handleCancelEditContent() {
    setVisible(false);
    setEditModalObject(undefined);
  }

  const columns = [
    {
      // title: "Language ID",
      title: <FormattedMessage id="LanguageTableHeaderLanguageId" />,
      dataIndex: "key",
      width: "20%",
    },
    {
      // title: "Label",
      title: <FormattedMessage id="LanguageTableHeaderLabel" />,
      dataIndex: "value",
      width: "60%",
    },
    {
      // title: "Action",
      title: <FormattedMessage id="LanguageTableHeaderAction" />,
      key: "action",
      render: (text: any, record: field) =>
        editable ? (
          <Button type="primary" onClick={() => OpenModalEditContent(record)}>
            <FormattedMessage id="StaticEditButton" />
          </Button>
        ) : null,
    },
  ];

  const resturctureData = () => {
    if (content) {
      const temp = Object.keys(content).map((key) => ({
        key,
        value: content[key],
      }));
      setState(temp);
    }
  };

  useEffect(() => {
    resturctureData();
  }, [content]);

  return (
    <>
      <Table
        dataSource={[...state]}
        columns={columns}
        size="small"
        rowKey={parent.key?.toString()}
        pagination={false}
      />
      <GeneralFormModal
        visible={visible}
        handleAction={handleEditContent}
        handleCancelAction={handleCancelEditContent}
        titleId={"StaticEditButton"}
        okTextId={"StaticUpdateButton"}
        editableFields={[
          {
            name: "value",
            labelId: "StaticContentLabel",
            type: "textarea",
            rules: [],
          },
        ]}
        editObject={editModalObject}
      />
      {modalContextHolder}
    </>
  );
};

export default ContentKeyValueTable;
