import React from "react";
import MenuTabs from "@components/Admin/MenuTabs";

const SurveyMenu: React.FC = () => (
  <MenuTabs
    items={[
      {
        id: "SurveySurveyNavigationTitle",
        path: "/admin/survey",
        page: "Survey",
        subpage: "Survey",
      },
    ]}
  />
);

export default SurveyMenu;
