import React, { useState, useEffect } from "react";
import {
  RatingScalesClient,
  RatingScalePointDto,
  SupportedLanguageClient,
} from "@api";
import { Table } from "lib";

type HeaderProps = {
  ratingScaleId: number;
  columns: any;
  expanded: boolean;
};

const RatingScalePointNestTable = ({
  ratingScaleId,
  columns,
  expanded,
}: HeaderProps): JSX.Element => {
  const allLanguageFetch = {
    fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
      const _init = {
        ...init,
        headers: {
          ...init?.headers,
          "accept-language": "*",
        },
      };
      return fetch(url, _init);
    },
  };

  const ratingScalesClient = new RatingScalesClient(
    undefined,
    allLanguageFetch
  );
  const supportedLanguageClient = new SupportedLanguageClient();

  const [state, setState] = useState({
    items: [] as RatingScalePointDto[],
    loading: false,
  });

  async function fetchItems() {
    setState({
      ...state,
      loading: true,
    });

    const supportedLocales =
      await supportedLanguageClient.getSupportedLanguages(undefined);
    const res = await ratingScalesClient.get(ratingScaleId);

    const baseContent: { [key: string]: string } = {};

    supportedLocales
      .map((v) => v.id as string)
      .forEach((element) => {
        baseContent[element] = "";
      });
    const temp =
      (res.ratingScalePoints as RatingScalePointDto[]) ||
      ([] as RatingScalePointDto[]);

    temp.forEach((element) => {
      element.label = { ...baseContent, ...element.label };
    });

    setState({
      items: temp,
      loading: false,
    });
  }

  useEffect(() => {
    if (expanded) fetchItems();
  }, [expanded]);

  return (
    <Table
      dataSource={[...state.items]}
      columns={columns}
      rowKey={"id"}
      loading={state.loading}
    />
  );
};

export default RatingScalePointNestTable;
